const partners = [
  {
    id: 1,
    name: "Simpe, funktionell and einmalig",
    image: require("../assets/images/cutra.png"),
    website: "https://cutraarchitekten.ch/",
    web: "cutraarchitekten.ch",
  },
  {
    id: 2,
    name: "Edness Versicherungstreuhand",
    image: require("../assets/images/edness.png"),
    website: "https://www.edness.ch/",
    web: "edness.ch",
  },
{
    id: 3,
    name: "Edrance Insurance",
    image: require("../assets/images/edrance.svg"),
    website: "https://www.edrance.ch/",
    web: "edrance.ch",
  },
  {
    id: 4,
    name: "Pro Studio Plus",
    image: require("../assets/images/prostudio.svg"),
    website: "https://prostudioplus.ch/",
    web: "prostudioplus.ch",
  },
  {
    id: 5,
    name: "Urs Walther AG",
    image: require("../assets/images/Waltherpartner.svg"),
    website: "https://www.urswalther-ag.ch/",
    web: "urswalther-ag.ch",
  },
  {
    id: 6,
    name: "Grize",
    image: require("../assets/images/grizepartner.svg"),
    website: "https://grize.ch/",
    web: "grize.ch",
  },
  {
    id: 7,
    name: "Purbe",
    image: require("../assets/images/pur.svg"),
    website: "https://www.purbe.ch/",
    web: "purbe.ch",
  },
  {
    id: 8,
    name: "Des-Tech",
    image: require("../assets/images/destech.svg"),
    website: "https://des-tech.ch/",
    web: "des-tech.ch",
  },
];

export default partners;
