import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import i18next, { t } from "i18next";
import banner from "../../assets/images/edmmobanner.png";
import bannerEn from "../../assets/images/edmmobannerEn.png";
import { Link } from "react-router-dom";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div
            className="hero-figure illustration-element-01"
            data-aos="flip-up"
            data-aos-duration="3000"
          >
            <a
              data-video="https://player.vimeo.com/video/665514715?h=fa7e668313"
              href="#0"
              className="videoja"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video.png")}
                alt="Hero"
                width={896}
                height={504}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/665514715?h=fa7e668313"
            videoTag="iframe"
          />
          <div
            className={tilesClasses}
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-05.svg")}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("Achievinggoalstogether")}</h4>
                  <p className="m-0 text-sm"></p>
                </div>
              </div>
            </div>

            <div className="tiles-item " data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-04.svg")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("Realizewishes")}</h4>
                  <p className="m-0 text-sm"></p>
                </div>
              </div>
            </div>

            <div className="tiles-item" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-06.svg")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("Bringyourownideas")}</h4>
                  <p className="m-0 text-sm"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="MehrfamilienhausTHÖRNATURE">
        <div className="banner" data-aos="fade-up" data-aos-duration="2000">
          {i18next.language === "de" ? (
            <img src={banner} alt="banner" />
          ) : (
            <img src={bannerEn} alt="banner" />
          )}
        </div>
      </Link>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
