import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../assets/scss/core/sections/_singleproject.scss";
import Button from "../elements/Button";
import dataa from "../../data/projects.json";
import { AiOutlineFilePdf } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import i18next, { t } from "i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
function SingleProject() {
  useTranslation([]);
  const [singleProject, setSingleProject] = useState([]);
  const [images, setImages] = useState([]);
  const { name } = useParams();
  const history = useHistory();
  AOS.init({
    once: false,
  });
  useEffect(() => {
    const findProject = () => {
      if (i18next.language === "en") {
        const newProject = dataa.projectsEN.find(
          (project) => project.link === name
        );
        setSingleProject(newProject);
        setImages(newProject.images);
      } else {
        const newProject = dataa.projectsDE.find(
          (project) => project.link === name
        );
        setSingleProject(newProject);
        setImages(newProject.images);
      }
    };
    findProject();
  }, [name, i18next.language]);
  return (
    <section className="container pt-5">
      <article
        key={singleProject.id}
        className="article pt-5"
        data-aos="fade-up"
        data-aos-duration="2300"
      >
        <div>
          <h2 className="h2">{singleProject.name}</h2>
          <p className="p">{singleProject.desc}</p>
          <p className="desc">
          <span style={{ fontWeight: "700" }}>{singleProject.desJahr}</span><br></br>
            <span style={{ fontWeight: "700" }}>{singleProject.desc2bold}</span>
            {singleProject.desc2}<br></br>
            <span style={{ fontWeight: "700" }}>{singleProject.fertig}</span>
            <span style={{ fontWeight: "500" }}>{singleProject.februar}</span>
          </p>
          <p style={{ marginBottom: "0px" }} className="desc">
            <span style={{ fontWeight: "700" }}>{singleProject.desc3bold}</span>
            {singleProject.desc3}
          </p>
          {singleProject.desc3 ? (
            <p className="desc">{singleProject.isAvailable}</p>
          ) : (
            ""
          )}
          <p style={{ marginBottom: "0px" }} className="desc">
            <span style={{ fontWeight: "700" }}>{singleProject.desc4bold}</span>
            {singleProject.desc4}
          </p>
          {singleProject.desc4 ? (
            <p className="desc">{singleProject.desc5}</p>
          ) : (
            ""
          )}
          <p style={{ marginBottom: "0px" }} className="desc">
            <span style={{ fontWeight: "700" }}>{singleProject.desc6bold}</span>

            {singleProject.desc6}
          </p>
          {singleProject.desc6 ? (
            <p className="desc">{singleProject.desc11}</p>
          ) : (
            ""
          )}
          <p style={{ marginBottom: "0px" }} className="desc">
            <span style={{ fontWeight: "700" }}>{singleProject.desc7bold}</span>

            {singleProject.desc7}
          </p>
          {singleProject.desc7 ? (
            <p className="desc">{singleProject.desc5}</p>
          ) : (
            ""
          )}
          <p style={{ marginBottom: "0px" }} className="desc">
            <span style={{ fontWeight: "700" }}>{singleProject.desc8bold}</span>

            {singleProject.desc8}
          </p>
          {singleProject.desc8 ? (
            <p className="desc">{singleProject.desc5}</p>
          ) : (
            ""
          )}
          <p className="desc">{singleProject.desc9}</p>
          <ImageGallery thumbnailPosition="bottom" items={images} />
        </div>
        {singleProject.plan?.map((data) => {
          return (
            <div className="pt-3">
              <Button>
                <a
                  href={data.href}
                  color="red"
                  className="pdffile"
                  download={data.download}
                >
                  <span className="icon">
                    <AiOutlineFilePdf />
                  </span>
                  {t("DownloadPlan")}
                  <span className="icon">
                    <AiOutlineDownload />
                  </span>
                </a>
              </Button>
              <div style={{ display: 'flex', flexDirection: 'column', textDecoration:'none', marginTop:'10px', }}>
              <h4 style={{ textDecoration: 'none', fontWeight: 'bold', marginBottom: '10px', color:'#CFB261' }}>{data.plan}</h4>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'column', textDecoration:'underline', marginTop:'15px' }}>
              
                <a href={data.link1}>{data.plan1}</a>
                <a href="https://www.immoscout24.ch/de/d/wohnung-kaufen-thoerishaus/7715755?utm_source=copy-link&utm_medium=social_sharing&utm_term=detail&utm_content=de&utm_campaign=is24_share_web">{data.plan2}</a>
                <a href="https://www.immoscout24.ch/de/d/wohnung-kaufen-thoerishaus/7712262?utm_source=copy-link&utm_medium=social_sharing&utm_term=detail&utm_content=de&utm_campaign=is24_share_web">{data.plan3}</a>
                <a href="https://www.immoscout24.ch/de/d/wohnung-kaufen-thoerishaus/7712261?utm_source=copy-link&utm_medium=social_sharing&utm_term=detail&utm_content=de&utm_campaign=is24_share_web">{data.plan4}</a>
                <a href="https://www.immoscout24.ch/de/d/wohnung-kaufen-thoerishaus/7712219?utm_source=copy-link&utm_medium=social_sharing&utm_term=detail&utm_content=de&utm_campaign=is24_share_web">{data.plan5}</a>
              </div> */}
            </div>
          );
        })}
        <div className="butoni">
          <HashLink smooth to="/#contact" color="primary" style={{ background: 'black', color: '#CFB261', height: '60px', borderRadius: '3px', padding: '10px', display: 'flex', alignItems: 'center', width: '190px', fontSize: '17px', fontWeight: '700'}}>
            {t("Kontaktieren")}
          </HashLink>
        </div>
        <div className="butoni">
          <Button onClick={history.goBack} color="primary">
            {t("GoBack")}
          </Button>
        </div>
      </article>
    </section>
  );
}

export default SingleProject;
