import React from "react";
import CountUp from "react-countup";
import { t } from "i18next";

function Banner() {
  return (
    <div className="banner" data-aos="fade-up" data-aos-duration="2000">
      <CountUp
        enableScrollSpy
        start={0}
        end={10}
        duration={2.5}
        separator="."
        suffix="+"
      >
        {({ countUpRef, start }) => (
          <div className="counter">
            <img
              alt=""
              style={{ marginBottom: "10px" }}
              src={require("./../assets/images/client.png")}
            />
            <span className="innercounter countertext" ref={countUpRef} />
            <p className="countertext">{t("Years")}</p>
          </div>
        )}
      </CountUp>
      <CountUp
        enableScrollSpy
        start={0}
        end={19}
        duration={2.5}
        separator="."
        suffix="+"
      >
        {({ countUpRef, start }) => (
          <div className="counter">
            <img
              alt=""
              style={{ marginBottom: "10px" }}
              src={require("./../assets/images/property.png")}
            />
            <span className="innercounter countertext" ref={countUpRef} />
            <p className="countertext">{t("Apartments")}</p>
          </div>
        )}
      </CountUp>
      <CountUp
        enableScrollSpy
        start={0}
        end={42}
        duration={2.5}
        separator="."
        suffix="+"
      >
        {({ countUpRef, start }) => (
          <div className="counter counter3rd ">
            <img
              alt=""
              style={{ marginBottom: "10px" }}
              src={require("./../assets/images/agent.png")}
            />
            <span className="innercounter countertext" ref={countUpRef} />
            <p className="countertext">{t("Partner")}</p>
          </div>
        )}
      </CountUp>
    </div>
  );
}

export default Banner;
