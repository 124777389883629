import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["de", "en"],
    lng: "de",
    backend: {
      loadPath: "/assets/i18n/{{lng}}/translation.json",
    },
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    fallbackLng: "de",
    debug: true,
    interpolation: {
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
