import React, { useEffect, useState } from "react";
import data from "../data/ourTeam.json";
import { Link } from "react-router-dom";
import "../assets/scss/core/sections/_teammember.scss";
import linkedin from "../assets/images/linkdin.svg";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "../components/elements/Button.js";
import { ScrollToTop } from "../components/elements/ScrollToTop";
function OurTeam() {
  useTranslation([]);
  const [fetch, setFetch] = useState([]);
  const [items] = useState(data);
  useEffect(() => {
    if (i18next.language === "en") {
      setFetch(items.ourTeamEN);
    } else {
      setFetch(items.ourTeamDE);
    }
  }, [i18next.language]);

  AOS.init({
    once: false,
  });
  return (
    <>
      <ScrollToTop />
      <section style={{ maxWidth: '1280px' }} className="container pt-5 team">
        {fetch.map(
          ({
            id,
            position,
            imgUrl,
            name,
            desc,
            desc1,
            desc2,
            desc3,
            email,
            linkedinUrl,
          }) => (
            <article
              key={id}
              style={{ paddingBottom: "4rem" }}
              className="member pt-5"
            >
              <img
                src={imgUrl}
                className="teamimage"
                alt=""
                data-aos="zoom-in"
                data-aos-duration="2000"
              />
              <div
                className="memberinfo"
                data-aos="flip-right"
                data-aos-duration="2500"
              >
                <h4 className="h2">{name}</h4>

                <div>
                  <h4 style={{ fontSize: "17px" }}>{position}</h4>
                  {/* <p style={{ fontSize: "17px", marginBottom: "0px" }}>
                    {desc}
                  </p>
                  <p style={{ fontSize: "17px", marginBottom: "0px" }}>
                    {desc1}
                  </p>
                  <p style={{ fontSize: "17px", marginBottom: "0px" }}>
                    {desc2}
                  </p>
                  {desc3 ? <p style={{ fontSize: "17px" }}>{desc3}</p> : ""} */}
                </div>

                <span style={{ fontSize: "17px", marginTop: "0px" }}>
                  {email}
                </span>

                <div>
                  <div className="bottom">
                    <a className="" href={linkedinUrl}>
                      <img alt="linkedin" src={linkedin} />
                    </a>
                  </div>
                </div>
              </div>
            </article>
          )
        )}
      </section>
      <div data-aos="fade-up" data-aos-duration="2300" style={{ width: '48%', margin: 'auto'}}>
          <Link to="/">
            <Button color="primary">{t("GoBack")}</Button>
          </Link>
        </div>
    </>
  );
}

export default OurTeam;
