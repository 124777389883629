import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { useTranslation } from "react-i18next";

const LayoutDefault = ({ children }) => {
  useTranslation([]);

  return (
    <>
      <Header navPosition="right" />
      <main className="site-content">{children}</main>
      <Footer />
    </>
  );
};

export default LayoutDefault;
