import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { HashLink } from "react-router-hash-link";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <HashLink smooth to="/#aboutUs">
            {t("AboutUs")}
          </HashLink>
        </li>
        <li>
          <HashLink smooth to="/projects">
            {t("Projects")}
          </HashLink>
        </li>
        <li>
          <HashLink smooth to="/ourTeam">
            {t("OurTeam")}
          </HashLink>
        </li>
        <li>
          <HashLink smooth to="/#contact">
            {t("Contact")}
          </HashLink>
        </li>
        <Link to={{ pathname: "/privacy" }} replace>
          {t("PrivacyPolicy")}
        </Link>
      </ul>
    </nav>
  );
};

export default FooterNav;
