import React from "react";
import "../../assets/scss/core/sections/_about.scss";
import p1 from "../../assets/images/aboutUs/1s.png";
import p5 from "../../assets/images/aboutUs/4s.png";
import logo from "../../assets/images/EdmmoSwiss2.svg";
import { RiTeamFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function About() {
  AOS.init({
    once: false,
  });
  useTranslation([]);
  return (
    <section className="pt-5 page-section" id="aboutUs">
      <div className="container">
        <div
          className="text-center"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h2 style={{ paddingRight: "50px" }} className="section-heading">
            {t("AboutUs")}
          </h2>
        </div>
        <div className="pt-5 pb-5">
          <ul
            className="timeline"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <li data-aos="fade-right" data-aos-duration="2000">
              <div className="timeline-image">
                <RiTeamFill
                  color="black"
                  className="rounded-circle img-fluid img-circle"
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading"></div>
                <div className="timeline-body">
                  <p className="text-muted">{t("AboutUs1")}</p>
                </div>
              </div>
            </li>
            <li
              data-aos="fade-left"
              data-aos-duration="2000"
              className="timeline-inverted panels"
            >
              <div className="timeline-image">
                <img className="img-fluid img-circle" src={p1} alt="" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading"></div>
                <div className="timeline-body">
                  <p className="text-muted">{t("AboutUs2")}</p>
                </div>
              </div>
            </li>
            <li className="panels">
              <div data-aos="fade-right" data-aos-duration="2000">
                <div className="timeline-image">
                  <img className="img-fluid img-circle" src={p5} alt="" />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading"></div>
                  <div className="timeline-body">
                    <p className="text-muted" style={{ marginRight: "38px" }}>
                      {t("AboutUs3")}
                    </p>
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="2500"
                className="specialPanel"
              >
                <div className="timeline-heading"></div>
                <div className="timeline-body">
                  <p className="text-muted">{t("AboutUs4")}</p>
                </div>
              </div>
            </li>
            <li className="panels" data-aos="fade-up" data-aos-duration="2000">
              <div className="timeline-image">
                <img className="img-fluid img-circle" src={logo} alt="" />
              </div>
              <div className="lastPanel">
                <div className="timeline-body">
                  <p
                    className="text-muted text-center"
                    style={{ fontWeight: "700" }}
                  >
                    {t("AboutUs5")}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default About;
