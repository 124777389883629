/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../assets/scss/core/sections/_projects.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import projects from "../data/project.js";
import projects from "../data/projects.json";
import { Link } from "react-router-dom";
import i18next, { t } from "i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Projects() {
  useTranslation([]);
  AOS.init({ once: true });
  const [fetch, setFetch] = useState([]);
  useEffect(() => {
    if (i18next.language === "en") {
      setFetch(items.projectsEN);
    } else {
      setFetch(items.projectsDE);
    }
  }, [i18next.language]);
  const [items] = useState(projects);
  AOS.init({
    once: false,
  });
  return (
    <>
      <section
        id="projects"
        className="projects"
        // data-aos="fade-up"
        // data-aos-duration="2300"
      >
        <div className="container pt-5 pb-5 text-center">
          <h2 className="section-title">{t("Projects")}</h2>
          <div className="row">
            {fetch.map(({ id, link, img, timer, name, available }) => (
              <div
                className="col-md-4 col-sm-8"
                data-aos="fade-up"
                data-aos-duration={timer}
                key={id}
              >
                <Link to={`/${link}`} className="link">
                  <div className="box-image">
                    <div
                      className="image cover-full transition"
                      style={{ backgroundImage: `url(${img})` }}
                    ></div>
                    {/* <div className="shadow cover-full transition">
                      {available ? (
                        <div className="sale">{t("Available")}</div>
                      ) : (
                        <div className="sale">{t("FreieMietwohnung")}</div>
                      )}
                    </div> */}
                    <div className="desc center">
                      <h4 style={{ fontSize: "19px" }}>{name}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Projects;
