import React /*{ useState }*/ from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
// import Image from "../elements/Image";
import { t } from "i18next";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div
            className="hero-content"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <h1 className="mt-0 mb-16">
              {t("LivemorebeautifullywithEdmmo")}
              <span className="text-color-primary">Edmmo</span>
            </h1>
            <div className="container-xs">
              {/* <p
                className="mb-32 reveal-from-bottom text-color-high"
                data-reveal-delay="400"
              >
                Buying a house is a big deal, literally. It’s likely the biggest
                financial endeavor of your life this far.
              </p> */}
              <div>
                <a href="#contact">
                  <Button color="primary" wideMobile>
                    {t("Let'sgo")}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
