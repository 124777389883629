import React from "react";

const Input = ({ name, value, onChange, error }) => {
  return (
    <div>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className="form-control shadow-none"
        name={name}
      ></input>
      {error && (
        <div className="alert alert-danger">{error.replaceAll('"', "")}</div>
      )}
    </div>
  );
};

export default Input;
