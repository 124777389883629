/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../assets/scss/core/sections/_projects.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "../elements/Button";
import { Link } from "react-router-dom";
import data from "../../data/projects.json";
import i18next, { t } from "i18next";

function Projects() {
  const [items] = useState(data);
  const [fetch, setFetch] = useState([]);
  useEffect(() => {
    if (i18next.language === "en") {
      setFetch(items.projectsEN);
    } else {
      setFetch(items.projectsDE);
    }
  }, [i18next.language]);
  return (
    <section id="projects" className="projects">
      <div
        className="container pt-5 pb-5"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <h1
          className="section-header center-content"
          style={{ margin: "0px", fontSize: "44px" }}
        >
          {t("Projects")}
        </h1>
        <div className="row">
          {fetch.map(({ id, name, img, timer, link, available, link1 }) => (
            <div
              className="col-md-4 col-sm-8"
              data-aos="fade-up"
              data-aos-duration={timer}
              key={id}
            >
              <div className="box-image">
                <div
                  className="image cover-full transition"
                  style={{ backgroundImage: `url(${img})` }}
                ></div>
                <div className="shadow cover-full transition">
                  {available ? (
                    <div className="sale">{t("Available")}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="desc center">
                  <h4 style={{ fontSize: "19px" }}>{name}</h4>
                  <Link to={`/${link}`}>{t("More")}...</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Link to="/projects">
            <Button color="primary" wideMobile>
              {t("Moreprojects")}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Projects;
