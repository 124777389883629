import React, { useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from "../../data/partners";
import { t } from "i18next";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Partners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    topDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 550,
    autoplaySpeed: 2500,
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [items] = useState(data);

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="partners"></div>
      <div
        style={{
          maxWidth: "100%",
          justifyContent: "center",
          background: "white",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div style={{ maxWidth: "58%", margin: "0 auto" }}>
          <Slider {...settings}>
            {items.map(({ id, name, image, website, web }) => (
              <div
                key={id}
                data-reveal-delay="200"
                // style={{
                //   maxWidth: "100%",
                //   height: "100%",
                //   display: "flex",
                //   justifyContent: "flex-end",
                // }}
              >
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: "100%",
                    margin: "0px",
                    padding: "5px",
                    border: "2px solid ",
                    borderRadius: "20px",
                  }}
                >
                  <div>
                    <img src={image} alt="" />
                  </div>
                  <div
                    className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider"
                    style={{
                      maxWidth: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignContent: "space-beetwen",
                    }}
                  >
                    <span className="testimonial-item-name text-color-low respo-text">
                      {name}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <span className="respo-text">{t("VisitWebsite")}: </span>
                      <span
                        className="testimonial-item-link respo-text"
                        style={{ marginLeft: "8px" }}
                      >
                        <a
                          href={`${website}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {web}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
