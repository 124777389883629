import React from "react";

const TextAreaField = ({ name, value, onChange, error }) => {
  return (
    <div>
      <textarea
        type="text"
        value={value}
        onChange={onChange}
        className="form-control shadow-none"
        name={name}
      ></textarea>
      {error && (
        <div className="alert alert-danger">{error.replaceAll('"', "")}</div>
      )}
    </div>
  );
};

export default TextAreaField;
